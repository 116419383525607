import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import TransactionLayout from '../layouts/TransactionLayout';
import BlankLayout from '../layouts/BlankLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen loadable={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    // Unified DashboardLayout Routes
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        // Public Routes
        { path: 'home', element: <Home /> },
        { path: 'games/:categSlug', element: <GamesByCateg /> },
        { path: 'games/:categSlug/:companyId', element: <GamesByCateg /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'vip', element: <VipTable /> },
        // Auth Routes
        {
          path: 'auth',
          element: (
            <GuestGuard>
              <Outlet />
            </GuestGuard>
          ),
          children: [
            {
              path: 'login',
              element: <Home popupAuth={'1'} />,
            },
            {
              path: 'register',
              element: <Register />,
            },

            {
              path: 'reset-password',
              element: <ResetPassword />,
            },
            { path: 'reset-password/:token', element: <ResetPasswordDialogEmail /> },
          ],
        },

        // Protected Routes
        {
          path: 'user',
          element: (
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'account', element: <AccountProfile /> },
            { path: 'banks', element: <MemberBankList /> },
            { path: 'wallet', element: <Wallet /> },
            { path: 'change-password', element: <ChangePassword /> },
            { path: 'kyc', element: <MemberKyc /> },
          ],
        },
        {
          path: 'history',
          element: (
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'bet', element: <GameHistory /> },
            { path: 'transaction', element: <TransactionHistory /> },
          ],
        },
        {
          path: 'referral',
          element: (
            <AuthGuard>
              <Referral />
            </AuthGuard>
          ),
        },
        {
          path: 'agent',
          element: (
            <AuthGuard>
              <Agent />
            </AuthGuard>
          ),
        },
        {
          path: 'invite',
          element: (
            <AuthGuard>
              <Invite />
            </AuthGuard>
          ),
        },
        {
          path: 'page',
          element: (
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: ':customPage', element: <CustomPage /> },
          ],
        },
      ],
    },

    // PayStatus Routes
    {
      path: 'payStatus',
      element: <LogoOnlyLayout />,
      children: [{ path: ':paymentMethod', element: <PaymentStatus />, index: true }],
    },

    // AngPau (Standalone Route)
    {
      path: '/angpau',
      element: <AngPau />,
    },

    // Download Page
    {
      path: '',
      element: <BlankLayout />,
      children: [{ path: 'download', element: <DownloadPage /> }],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordDialogEmail = Loadable(
  lazy(() => import('../layouts/dashboard/header/forgotpassword/ResetPasswordDialogEmail'))
);
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));

// HOME
const Home = Loadable(lazy(() => import('../views/game/Home')));

// GAMES
const GamesByCateg = Loadable(lazy(() => import('../views/game/GamesByCateg')));

// GAME HISTORY
const GameHistory = Loadable(lazy(() => import('../views/history/GameHistory')));

// TRANSACTION HISTORY
const TransactionHistory = Loadable(lazy(() => import('../views/history/TransactionHistory')));

// USER
const AccountProfile = Loadable(lazy(() => import('../views/user/AccountProfile')));
const MemberBankList = Loadable(lazy(() => import('../views/user/MemberBankList')));
const Wallet = Loadable(lazy(() => import('../views/user/Wallet')));
const MemberKyc = Loadable(lazy(() => import('../views/user/MemberKyc')));

// INVITE
const Invite = Loadable(lazy(() => import('../views/share/Invite')));

// PROMOTION
const Promotion = Loadable(lazy(() => import('../views/promotion/Promotion')));
const ViewPromotion = Loadable(lazy(() => import('../views/promotion/ViewPromotion')));
const AngPau = Loadable(lazy(() => import('../views/promotion/AngPau')));

// PAYMENT STATS
const PaymentStatus = Loadable(lazy(() => import('../pages/PaymentStatus')));

// REFERRAL
const Referral = Loadable(lazy(() => import('../views/referral/Referral')));

// Bonus
const VipTable = Loadable(lazy(() => import('../views/referral/VipTable')));
const Agent = Loadable(lazy(() => import('../views/referral/Agent')));

// CUSTOM PAGE
const CustomPage = Loadable(lazy(() => import('../views/CustomPages')));

// DOWNLOAD PAGE
const DownloadPage = Loadable(lazy(() => import('../views/DownloadPage')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
