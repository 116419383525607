import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, FormHelperText, Stack, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { useNavigate } from 'react-router';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import palette from '../../../../theme/palette';
import CustomModal from '../../../../components/CustomModal';
import { StyledTextField } from '../../../GlobalStyles';
import uuidv4 from '../../../../utils/uuidv4';
import { checkIfNumberExist } from '../../../../utils/formatObject';
import { getOtpErrorMessage } from '../../../../utils/formatString';
import PhonenumberTextField2 from '../../../../components/PhonenumberTextField2';

const Label = styled(Typography)({
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontWeight: '600',
});

const LabelSpan = styled('span')({
  color: palette.visitor.dialog.span,
  fontSize: '14px',
  fontWeight: '600',
});

const defaultForm = {
  userPhoneNumber: '',
  captcha: '',
};

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const [error, setError] = useState('');

  const [formError, setFormError] = useState({});

  const [formData, setFormData] = useState(defaultForm);

  // const [isInit, setIsInit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (openForgotPasswordDialog) {
      setTimeout(() => loadCaptchaEnginge(4), 100);
    }
  }, [openForgotPasswordDialog]);

  const handleFormChange = (e) => {
    // const regex = /^\d+$/;
    // if (e.target.name === 'userPhoneNumber' && e.target.value && !regex.test(e.target.value)) return;

    setFormData((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };

  const handleClose = () => {
    setOpenForgotPasswordDialog(false);
    setFormData(defaultForm);
  };

  const isValid = () => {
    const _errors = {};
    let valid = true;

    if (!formData?.userPhoneNumber) {
      valid = false;
      _errors.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    }

    if (validateCaptcha(formData?.captcha?.trim()) === false) {
      valid = false;
      setFormData((prev) => ({ ...prev, captcha: '' }));
      _errors.captcha = translate('captcha_not_match');
    }

    setFormError(_errors);

    return valid;
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    try {
      setIsLoading(true);

      if (!isValid()) return;

      const isExists = await checkIfNumberExist(formData?.userPhoneNumber);

      if (!isExists) throw new Error(translate('number_not_exists'));

      let fingerprint = localStorage.getItem('fingerprint');

      if (!fingerprint) {
        fingerprint = uuidv4();
        localStorage.setItem('fingerprint', fingerprint);
      }

      const response = await conObj.post('otp_request.json', {
        no_hp: formData?.userPhoneNumber.replace(/[^0-9]/g, ''),
        fingerprint,
        purpose: 'forgotpassword',
      });

      navigate('/auth/reset-password', {
        state: {
          otpUuid: response.data?.data?.data?.otp_uuid,
          phoneNumber: formData?.userPhoneNumber.replace(/[^0-9]/g, ''),
        },
      });

      setOpenForgotPasswordDialog(false);
    } catch (error) {
      console.error(error);
      setError(getOtpErrorMessage(translate, error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      open={openForgotPasswordDialog}
      onClose={() => setOpenForgotPasswordDialog(false)}
      title={translate('forgot_password')}
    >
      <Box sx={{ p: 2 }}>
        {error ? (
          <Alert severity="error" color="error" sx={{ my: 1 }}>
            {error}
          </Alert>
        ) : (
          ''
        )}
        <Box sx={{ mb: 2 }}>
          <Label fontWeight={600}>
            {translate('phone_number')} <LabelSpan>*</LabelSpan>
          </Label>
          <PhonenumberTextField2
            name="userPhoneNumber"
            placeholder={translate('fill_up_here')}
            value={formData?.userPhoneNumber}
            onChange={(value) => handleFormChange({ target: { value, name: 'userPhoneNumber' } })}
            fullWidth
          />
          {formError?.userPhoneNumber ? <FormHelperText error>{formError?.userPhoneNumber}</FormHelperText> : ''}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Label fontWeight={600}>
            {translate('captcha')} <LabelSpan>*</LabelSpan>
          </Label>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <LoadCanvasTemplateNoReload />
            </Box>

            <StyledTextField
              name="captcha"
              placeholder={translate('enter_text_on_the_left')}
              value={formData?.captcha}
              onChange={handleFormChange}
              fullWidth
            />
          </Box>
          {formError?.captcha ? <FormHelperText error>{formError?.captcha}</FormHelperText> : ''}
        </Box>

        <LoadingButton
          sx={{
            width: '100%',
            backgroundColor: palette.button.bg,
            color: '#fff',
            '&:focus,:hover': { backgroundColor: palette.button.bg },
          }}
          onClick={() => onSubmit()}
          loading={isLoading}
        >
          {translate(`confirm`)}
        </LoadingButton>
      </Box>
    </CustomModal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
